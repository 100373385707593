<template>
  <div class="login d-flex justify-content-center">
    <div class="areawrap login-wrap">
      <h2 class="mb-4">
        Welcome
      </h2>

      <b-form-group label="Email" class="mb-6" :state="$v.form.$anyDirty ? $v.form.email.required : null">
        <b-form-input v-model="form.email" placeholder="Enter Email" />
      </b-form-group>
      <b-form-group label="Password" class="mb-0" :state="$v.form.$anyDirty ? !$v.form.password.$anyError : null">
        <b-form-input
          v-model="form.password"
          :type="isShowPassword ? 'text' : 'password'"
          placeholder="Enter Password"
          @input="changeErrorPasswordStatus"
        />
        <font-awesome-icon
          v-if="isShowPassword"
          :icon="['fas', 'eye']"
          size="lg"
          fixed-width
          class="form-group-icon"
          @click="isShowPassword = !isShowPassword"
        />
        <font-awesome-icon
          v-else
          :icon="['fas', 'eye-slash']"
          size="lg"
          fixed-width
          class="form-group-icon"
          @click="isShowPassword = !isShowPassword"
        />
      </b-form-group>

      <span v-if="isErrorPassword" class="text-danger font-size-sm">
        Incorrect username or password
      </span>

      <!-- <div class="recaptcha d-flex justify-content-center mt-6">
        <vue-recaptcha
          ref="recaptcha"
          sitekey="6LezWZUbAAAAAI9CdiJdp5YVAKv4_0Z3-qZCr0H1"
          :load-recaptcha-script="true"
          @verify="onVerify"
        />
      </div> -->

      <div class="text-right mt-6">
        <b-button variant="primary" class="btn-width" @click="login">
          LOG IN
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
// import VueRecaptcha from 'vue-recaptcha'
import { required } from 'vuelidate/lib/validators'

export default {
  name: 'Login',
  // components: {
  //   VueRecaptcha
  // },
  data () {
    return {
      isShowPassword: false,
      isErrorPassword: false,
      form: {
        email: '',
        password: '',
        role: 3
      }
    }
  },
  validations () {
    return {
      form: {
        email: { required },
        password: {
          required,
          error () {
            return !this.isErrorPassword
          }
        }
      }
    }
  },
  methods: {
    onVerify (response) {
      console.log('驗證成功')
      // console.log(response)
    },
    changeErrorPasswordStatus () {
      this.isErrorPassword = false
    },
    login () {
      this.$v.form.$touch()
      if (this.$v.form.$anyError) return
      this.$store.dispatch('login', this.form).then(() => {
        this.$router.push({ name: 'PartnerList' })
      }).catch(() => {
        this.isErrorPassword = true
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.login-wrap {
  width: 26rem;
}
.recaptcha {
  transform: scale(1.05);
}
</style>
